import Pristine from '../../node_modules/pristinejs/dist/pristine.js'

export default class Lightbox {
    constructor(box) {
        this.box = box
        // large image
        this.imagesLarge = this.box.querySelectorAll('.image-lightbox-large')
        this.imageLargeThumbnail = this.imagesLarge[0].getAttribute('href')
        this.imageLargeMain = this.imagesLarge[0]
            .querySelector('img')
            .getAttribute('src')
        //thumbnails
        this.imagesSmall = this.box.querySelectorAll('.image-lightbox-small')
        // this.image = this.link.href
        this.lightboxImagesArray = this.createLighboxImagesArray()

        //lightbox
        this.activeLiveboxImage = this.imageLargeMain

        this.lightbox = this.box.querySelectorAll('.lightbox')
        this.lightboxContent = this.box.querySelector('.lightbox__content')
        this.closeBtn = this.box.querySelectorAll('.lightbox__close')
        this.imgCont = this.box.querySelector('.lightbox__image')
        this.next = this.box.querySelectorAll('.lightbox__next')
        this.prev = this.box.querySelectorAll('.lightbox__prev')

        this.imagesLarge.forEach(el => {
            el.addEventListener('click', e => {
                e.preventDefault()
                this.lightbox[0].classList.add('lightbox--open')
                this.imgCont.src = this.activeLiveboxImage
            })
        })

        this.imagesSmall.forEach(el =>
            el.addEventListener('click', e => this.clickThumbnail(e))
        )
        this.next.forEach(el => {
            el.addEventListener('click', e => this.clickNextImage(e))
        })
        this.prev.forEach(el => {
            el.addEventListener('click', e => this.clickPrevImage(e))
        })
        this.closeBtn.forEach(el => {
            el.addEventListener('click', e => {
                this.lightbox[0].classList.remove('lightbox--open')
            })
        })

        this.lightbox.forEach(el => {
            el.addEventListener('click', e => {
                var isClickInside = this.lightboxContent.contains(e.target)

                if (!isClickInside) {
                    this.lightbox[0].classList.remove('lightbox--open')
                }
            })
        })
    }
    createLighboxImagesArray() {
        const imagesSmall = []
        this.imagesSmall.forEach(el =>
            imagesSmall.push(el.getAttribute('href'))
        )
        return imagesSmall
    }

    clickNextImage(e) {
        e.preventDefault()
        const index = this.lightboxImagesArray.indexOf(this.activeLiveboxImage)
        const nextImg =
            index + 2 <= this.lightboxImagesArray.length
                ? this.lightboxImagesArray[index + 1]
                : this.lightboxImagesArray[0]
        this.activeLiveboxImage = nextImg
        this.box.querySelector('.lightbox__image').src = nextImg
    }
    clickPrevImage(e) {
        e.preventDefault()
        const index = this.lightboxImagesArray.indexOf(this.activeLiveboxImage)
        const prevImg =
            index === 0
                ? this.lightboxImagesArray[this.lightboxImagesArray.length - 1]
                : this.lightboxImagesArray[index - 1]
        this.activeLiveboxImage = prevImg
        this.box.querySelector('.lightbox__image').src = prevImg
    }
    clickThumbnail(e) {
        e.preventDefault()

        const newImageSrc = e.currentTarget.getAttribute('href')

        this.activeLiveboxImage = newImageSrc
        this.box.querySelector('.image-lightbox-large img').src = newImageSrc
    }
}
