//required for webpack
import '../styles/main.scss'
import Choices from '../node_modules/choices.js/public/assets/scripts/choices.js'
import OverlayScrollbars from '../node_modules/overlayscrollbars/js/OverlayScrollbars.js'

import SmoothScroll from '../node_modules/smooth-scroll/dist/smooth-scroll.js'
import { tns } from '../node_modules/tiny-slider/src/tiny-slider'

// imports to all components
import Collapse from './utils/collapse'
import Validation from './components/validation'
import CheckAll from './components/checkAll'
import Lightbox from './components/lightbox'
import SelectLink from './components/SelectLink'
import Cookies from './components/cookies'
import TabMenu from './components/tabMenu'

class Site {
    constructor() {
        this.parseContent()
    }

    find(selector) {
        return this.root.matches(selector)
            ? [this.root]
            : Array.from(this.root.querySelectorAll(selector))
    }

    parseContent(root = document.body) {
        this.root = root
        new Cookies()

        this.find('[data-toggle="collapse"]').forEach((el) => {
            new Collapse(el)
        })
        this.find('.lightbox__container').forEach((el) => {
            new Lightbox(el)
        })

        new SmoothScroll('a.smooth-scroll[href*="#"]')

        this.find('.homepage__slider').forEach((el) => {
            const slider = tns({
                container: '.homepage__slider',
                items: 1,
                slideBy: 'page',
                autoplay: true,
                mouseDrag: true,
                controls: true,
                navPosition: 'bottom',
                autoplayButton: false,
                autoplayButtonOutput: false,
            })
        })
        this.find('.article__thumbs').forEach((el) => {
            const slider = tns({
                container: '.article__thumbs',
                fixedWidth: 100,
                swipeAngle: false,
                speed: 400,
                autoplay: false,
                gutter: 4,
            })
        })

        this.find('.select').forEach((el) => {
            new Choices(el, {
                placeholder: false,
                searchEnabled: false,
                itemSelectText: '',
                classNames: {
                    itemSelectable: 'form__select-item',
                },
            })
        })

        this.find('.select-number').forEach((el) => {
            new Choices(el, {
                placeholder: false,
                searchEnabled: false,
                itemSelectText: '',
                removeItems: true,
            })
        })
        this.find('.select-search').forEach((el) => {
            new Choices(el, {
                placeholder: true,
                searchEnabled: true,
                itemSelectText: '',
            })
        })

        this.find('.select__link').forEach((el) => {
            new SelectLink(el)
        })

        var instance = OverlayScrollbars(
            document.querySelectorAll('.scrollbar'),
            {
                className: 'os-theme-dark',
            }
        )

        this.find('.form--validate').forEach((el) => {
            new Validation(el)
        })

        this.find('.check-all').forEach((el) => {
            new CheckAll(el)
        })

        this.find('.subscription-type').forEach((el) => {
            const content = document.querySelector('.registration__content')
            el.addEventListener('change', (e) => {
                content.classList.toggle('registration__content--free')
                content.classList.toggle('registration__content--by-month')
            })
        })

        this.find('.action-label__close').forEach((el) => {
            el.addEventListener('click', (e) => {
                el.closest('.action-label').classList.add('action-label--hide')
            })
        })

        this.find('.tabmenu').forEach((el) => {
            new TabMenu(el)
        })
    }
}

new Site()
