import Pristine from "../../node_modules/pristinejs/dist/pristine.js";

export default class CheckAll {
  constructor(checkbox) {
    this.checkbox = checkbox;
    this.class = this.checkbox.getAttribute("data-check");
    this.allCheckboxes = document.querySelectorAll(`.${this.class}`);

    this.checkbox.addEventListener("change", e => {
      if (e.target.checked) {
        this.allCheckboxes.forEach(el => {
          el.checked = true;
        });
      } else {
        this.allCheckboxes.forEach(el => {
          el.checked = false;
        });
      }
    });
  }
}
