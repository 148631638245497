//******************** *
// cookies
//******************** *
export default class Cookies {
    constructor() {
        this.cookiesPopup = document.querySelector('#cookies-message')
        this.closeButton = document.querySelectorAll('#accept-cookies-checkbox')
        window.onload = this.WHCheckCookies()

        this.closeButton.forEach(el =>
            el.addEventListener('click', e => this.WHCloseCookiesWindow(e))
        )
    }

    Close_popup_Cookie() {
        var date = new Date()
        date.setTime(date.getTime() + 1 * 60 * 60 * 1000)
        var expires = '; expires=' + date.toGMTString()
        document.cookie = 'newsletter_popup_close=yes' + expires + '; path=/'
    }

    WHCreateCookie(name, value, days) {
        var date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        var expires = '; expires=' + date.toGMTString()
        document.cookie = name + '=' + value + expires + '; path=/'
    }
    WHReadCookie(name) {
        var nameEQ = name + '='
        var ca = document.cookie.split(';')
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i]
            while (c.charAt(0) == ' ') c = c.substring(1, c.length)
            if (c.indexOf(nameEQ) === 0)
                return c.substring(nameEQ.length, c.length)
        }
        return null
    }
    WHCloseCookiesWindow(e) {
        e.preventDefault()
        this.WHCreateCookie('cookies_accepted', 'T', 365)
        this.cookiesPopup.style.display = 'none'
    }

    WHCheckCookies() {
        if (this.WHReadCookie('cookies_accepted') != 'T') {
            this.cookiesPopup.style.display = 'block'
        }
    }
}
