import Pristine from "../../node_modules/pristinejs/dist/pristine.js";

export default class Validation {
  constructor(form) {
    this.form = form;
    this.required = this.form.querySelectorAll("input[required]");
    this.addRequiredMessages();
    this.email = this.form.querySelectorAll("input[type='email']");
    this.addEmailMessages();
    this.pristine = new Pristine(this.form);

    this.form.addEventListener("submit", e => {
      e.preventDefault();
      // check if the form is valid
      const valid = this.pristine.validate(); // returns true or false

      const passwordValid = this.comparePasswordFields();

      if (valid === true && passwordValid) {
        e.currentTarget.submit();
      }
    });
  }
  addRequiredMessages() {
    this.required.forEach(el => {
      el.setAttribute(
        "data-pristine-required-message",
        "To pole jest wymagane"
      );
    });
  }
  addEmailMessages() {
    this.email.forEach(el => {
      el.setAttribute(
        "data-pristine-email-message",
        "Wpisz poprawny adres email"
      );
    });
  }

  comparePasswordFields() {
    const password = this.form.querySelector(".input__password");
    let passwordsAreTheSame = true;
    if (password !== null) {
      const repeatPassword = this.form.querySelector(".input__repeat-password");
      const passwordVal = password.value;
      const repeatPasswordVal = repeatPassword.value;

      if (
        passwordVal !== "" &&
        repeatPasswordVal !== "" &&
        repeatPasswordVal !== passwordVal
      ) {
        this.pristine.addError(repeatPassword, "hasła muszą być te same");
        passwordsAreTheSame = false;
      }
    }
    return passwordsAreTheSame;
  }
}
