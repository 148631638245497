export default class SelectLink {
    constructor(select) {
        this.select = select

        this.select.addEventListener('change', this.openPage.bind(this))
    }

    openPage(e) {
        const link = this.select.value
        window.location = link
    }
}
