export default class Collapse {
    constructor(button) {
        this.button = button
        // this.initialHeight = initialHeight;

        this.target = this.button.getAttribute('data-target')
        this.collapseElement = document.querySelector(this.target)
        this.initialHeight = this.collapseElement.style.height
        this.buttonText = this.button.textNode
        this.button.addEventListener('click', this.toggleCollapse.bind(this))

        if (this.collapseElement.classList.contains('collapse--open-onload')) {
            const height = this.collapseElement.offsetHeight
            this.collapseElement.style.height = height + 'px'
        }
    }

    toggleCollapse() {
        this.button.classList.toggle('collapse--open')
        this.button.classList.toggle('collapse--close')
        this.collapseElement.classList.remove('collapse--open-onload')
        if (this.collapseElement.classList.contains('collapse--open')) {
            this.collapseElement.style.height = this.initialHeight
        } else {
            const height = this.collapseElement.firstElementChild.offsetHeight

            this.collapseElement.style.height = height + 'px'
        }
        this.collapseElement.classList.toggle('collapse--open')
    }
}
