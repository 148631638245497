export default class TabMenu {
    constructor(tabMenu) {
        this.tabMenu = tabMenu
        this.tabs = this.tabMenu.querySelectorAll('.tabmenu__tab')

        this.tabs.forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault()
                if (
                    !e.currentTarget.classList.contains('tabmenu__tab--active')
                ) {
                    this.removeAcitveClass()
                    this.setActiveTab(e.currentTarget)
                }
            })
        })
    }

    removeAcitveClass() {
        const activeTab = this.tabMenu.querySelector('.tabmenu__tab--active')
        activeTab && activeTab.classList.remove('tabmenu__tab--active')
        const activeContent = document.querySelector(
            '.tabmenu__content--active'
        )
        activeContent &&
            activeContent.classList.remove('tabmenu__content--active')
    }

    setActiveTab(tab) {
        tab.classList.add('tabmenu__tab--active')
        const selector = tab.getAttribute('data-tab-content')

        document
            .querySelector(selector)
            .classList.add('tabmenu__content--active')
    }
}
